import { html } from 'lit-html';
import './home.scss';
import { VerseOfTheDay } from '../../components/verse-of-the-day/verse-of-the-day';

export const home = () => {
  return html`<section class="home">
    <header class="page-header fullscreen">
      <div class="content">
        <img src="/logo.svg" alt="logo" />

        <br />

        <h1>Christ Church Horison</h1>

        <br />

        <h5>God’s community of redeemed families in ministry.</h5>

        <hr />

        <a href="/about/contact-us">Get in Touch</a>
        <a class="filled" href="/about-us">Learn More</a>
      </div>
      <div class="image">
        <img id="header-image" src="headersmall.jpg" alt="Church" loading="lazy" />
      </div>
    </header>

    <article class="page-content full about-section">
      <div class="inner-wrapper">
        <p>
          Hello. We are a close community of God’s people, who meet together regularly to worship and study our Saviour’s word. We love the Gospel of the Lord Jesus Christ through which we have been
          saved and are eager to share it with any who will listen!
        </p>
        <p>
          We can sum up what is important to us: it is to live as
          <strong>God’s community of redeemed families in ministry</strong>. If you want to know what that means please look up <a href="/about-us">about us</a>.
        </p>
      </div>
    </article>

    <article class="youtube-video">
      <!-- <div class="inner-wrapper"> -->
      <iframe
        loading="lazy"
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/we7nGfekIUU?controls=0"
        title="CCH Channel Trailer"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <!-- </div> -->
    </article>

    <article class="newsroom">
      <div class="inner-wrapper">
        <p>
          Engaged is a collection of articles and resources from Christ Church Horison on various topics. These resources are provided to help you engage with the culture using the scripture alone as
          your foundation of truth in the reformed tradition.
        </p>

        <div class="action">
          <a href="https://engaged.christchurchhorison.co.za">View Our Resources</a>
        </div>
      </div>
    </article>

    <!-- Verse of the day -->
    ${VerseOfTheDay()}
    <!-- Verse of the day -->

    <article class="page-content full actions">
      <div class="actions-wrapper">
        <a class="action" routerLink="/schedule">
          <div class="inner">
            <div class="icon">
              <svg fill="none" viewBox="0 0 150 150">
                <path
                  fill="#F59327"
                  d="M25 37.5H12.5V125c0 6.875 5.625 12.5 12.5 12.5h87.5V125H25V37.5zm100-25H50c-6.875 0-12.5 5.625-12.5 12.5v75c0 6.875 5.625 12.5 12.5 12.5h75c6.875 0 12.5-5.625 12.5-12.5V25c0-6.875-5.625-12.5-12.5-12.5zm0 87.5H50V25h75v75zM62.5 56.25h50v12.5h-50v-12.5zm0 18.75h25v12.5h-25V75zm0-37.5h50V50h-50V37.5z"
                />
              </svg>
            </div>
            <div class="label">SCHEDULE</div>
          </div>
        </a>

        <a class="action" routerLink="/sermons">
          <div class="inner">
            <div class="icon">
              <svg fill="none" viewBox="0 0 150 150">
                <path
                  fill="#F59327"
                  d="M18.8 56.3v37.5h25L75 125V25L43.7 56.3h-25zm43.7-1.1v39.6L48.9 81.3H31.3V68.8h17.6l13.6-13.6zM103.1 75c0-11-6.3-20.6-15.6-25.2v50.3A28 28 0 00103.1 75zM87.5 20.2V33a43.8 43.8 0 010 83.8v13a56.2 56.2 0 000-109.7z"
                />
              </svg>
            </div>
            <div class="label">SERMONS</div>
          </div>
        </a>

        <a class="action" routerLink="/about/contact-us">
          <div class="inner">
            <div class="icon">
              <svg fill="none" viewBox="0 0 150 150">
                <path
                  fill="#F59327"
                  d="M137.5 18.8h-125C5.6 18.8 0 24.4 0 31.1v87.5c0 7 5.6 12.5 12.5 12.5h125c6.9 0 12.4-5.6 12.4-12.4l.1-87.5c0-7-5.6-12.6-12.5-12.6zm0 100h-125V31.3h125v87.5zm-18.7-6.4l12.5-12.4-9.5-12.5h-10.2a37.8 37.8 0 010-25h10.2l9.5-12.5-12.5-12.4a46.7 46.7 0 000 74.8zM56.3 75a18.8 18.8 0 000-37.5 18.8 18.8 0 00-18.8 18.8c0 10.3 8.4 18.7 18.8 18.7zm0-25a6.3 6.3 0 010 12.5 6.3 6.3 0 01-6.3-6.3c0-3.4 2.8-6.2 6.3-6.2zm37.5 53.7c0-15.6-24.9-22.4-37.5-22.4-12.7 0-37.5 6.8-37.5 22.4v8.8h75v-8.8zM34.3 100a44.5 44.5 0 0122-6.3 44 44 0 0122 6.3h-44z"
                />
              </svg>
            </div>
            <div class="label">GET IN TOUCH</div>
          </div>
        </a>
      </div>
    </article>
  </section>`;
};
