import { html } from 'lit-html';
import './contact-card.scss';

export const ContactCard = (
  name: string,
  surname: string,
  email: string,
  role: string,
  phone?: string,
  avatar?: string
) => html`
  <div class="contact-card">
    <div class="avatar">
      <img width="100" height="100" src="${avatar || 'avatar.svg'}" alt="">
    </div>
    <div class="information">
      <h5 class="pastor">${name} ${surname}</h5>
      <h6 class="title">${role}</em></h6> 
    </div>
    ${
      !!email
        ? html`<div class="details">
            <a
              target="_blank"
              rel="noopener"
              href="mailto:${email}"
              class="icon"
            >
              ${email}
            </a>
          </div>`
        : html`<div class="details">No Contact Details</div>`
    }
  </div>
`;
