import { html } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import './verse-of-the-day.scss';

interface VOTD {
  text: string;
  content: string;
  display_ref: string;
  reference: string;
  permalink: string;
  copyright: string;
  copyrightlink: string;
  audiolink: string;
  day: string;
  month: string;
  year: string;
  version: string;
  version_id: string;
}

function replaceAmp(input: string): string {
  return input.replace(/(\&amp;)/gi, '&');
}

export const VerseOfTheDay = () => {
  const data: VOTD = JSON.parse(window.localStorage.getItem('votd'));

  if (!!data) {
    return html`
      <div class="verse-wrapper">
        <div class="inner">
          <div class="title">
            <div class="icon">
              <svg fill="none" viewBox="0 0 200 200">
                <defs />
                <path
                  fill="#000"
                  fill-rule="evenodd"
                  d="M190 100A90 90 0 1171 15v132l29-34 29 34V15c35 12 61 45 61 85zm10 0a100 100 0 11-200 0 100 100 0 01200 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="info">
              <h5 class="reference">
                <a href="${data.audiolink}" target="_blank" rel="noopener">
                  ${data.reference} 🔉
                </a>
              </h5>
              <h6 class="version">${data.version}</h6>
            </div>
          </div>
          <div class="verse-html">
            <p>${unsafeHTML(data.text)}</p>
          </div>
          <div class="verse-copy">
            <small>
              ${!!data.copyright
                ? html`<a
                    class="copy"
                    href="${replaceAmp(data.copyrightlink)}"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ${unsafeHTML(data.copyright)}
                  </a>`
                : ``}

              <strong>
                Powered by
                <a
                  href="https://www.biblegateway.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BibleGateway.com
                </a>
              </strong>
            </small>
          </div>
        </div>
      </div>
    `;
  } else {
    return html`<div class="verse-wrapper"></div>`;
  }
};
