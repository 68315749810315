import { navigate } from './nav';

export function crawlAnchors(hookAttribute: string = 'href'): void {
  const anchors = document.querySelectorAll(`a[${hookAttribute}]`);
  Array.from(anchors).forEach((anchor: HTMLAnchorElement) => {
    if (hookAttribute !== 'href') {
      anchor.href = anchor.getAttribute(hookAttribute);
    }

    const { href } = anchor;
    anchor.addEventListener(
      'click',
      (event: MouseEvent) => {
        event.preventDefault();
        navigate(href);
      },
      { once: true }
    );
  });
}
