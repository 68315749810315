import { html } from 'lit-html';
import './contact-us.scss';
import { ContactCard } from '../../components/contact-card/contact-card';

export const contactUs = () => {
  return html`
    <section class="contact-page">
      <header class="contact-header">
        <div class="inner">
          <h2>Get In Touch</h2>
          <p>
            Please feel free to contact one of us with any questions or queries.
            For general queries, questions, or comments, you can email us on
            <h5><a href="mailto:contact@christchurchhorison.co.za">
              contact@christchurchhorison.co.za
            </a></h5>
          </p>
        </div>
      </header>
      <article class="contact-cards">
        <ul>
          <li>
            ${ContactCard(
              'Michael',
              'Marsland',
              'michael@christchurchhorison.co.za',
              'Pastor',
              null,
              '/mike.jpeg'
            )}
          </li>
        
          <li>
            ${ContactCard(
              'Neil',
              'Britz',
              null,
              'Warden',
              null,
              '/neilbritz.jpeg'
            )}
          </li>
        </ul>
      </article>

      <article class="having-trouble">
        <p>
          If you are having trouble with this website or if you have noticed
          <br />
          something that isn't working, please send an email to
          <br />
          <h5>
            <a
              target="_blank"
              rel="noopener"
              href="mailto:issues@christchurchhorison.co.za"
            >
              issues@christchurchhorison.co.za
            </a>
          </h5>
        </p>
      </article>
    </section>
  `;
};
