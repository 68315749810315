import { html, TemplateResult } from 'lit-html';

export function getBiblereference(
  reference: string,
  useAs?: 'anchor' | null
): string | TemplateResult {
  const url = `https://www.biblegateway.com/passage/?search=${reference}&version=ESV`;

  if (useAs === 'anchor') {
    return html`<a target="_blank" rel="noopener" href="${url}">
      ${reference}
    </a>`;
  } else return url;
}
