import { html } from 'lit-html';
import './loader.scss';

export const loader = () => html`<div class="loader">
  <div class="inner">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>`;
