import { html } from 'lit-html';
import './navbar.scss';
import { navigate } from '../../lib/nav';
import { update } from '../../index';

let isMenuOpen: boolean = false;

function active(url: string, exact = false): string {
  if (!exact) {
    return window.location.pathname.includes(url) ? ' active' : '';
  } else {
    return window.location.pathname === url ? ' active' : '';
  }
}

function matchMediaMaxWidth(width: number, callback: (boolean) => void): void {
  if (typeof window.matchMedia === 'function') {
    const media = window.matchMedia(`screen and (max-width: ${width}px)`);
    media.onchange = () => {
      callback(media.matches);
    };
  } else {
    const handler = () => {
      callback(window.innerWidth <= width);
    };
    window.addEventListener('resize', handler);
  }
}

function nav(url: string, e?: Event): void {
  isMenuOpen = false;
  navigate(url, e);
}

const NavbarContent = () => html`
  <div class="content">
    <ul class="nav-list">
      <li class="nav-list-item">
        <a @click=${e => nav('/', e)} href="/" class="nav-link${active('/', true)}">Home</a>
      </li>

      <li class="nav-list-item">
        <a @click=${e => nav('/schedule', e)} href="/schedule" class="nav-link${active('/schedule')}">Schedule</a>
      </li>

      <li class="nav-list-item">
        <a @click=${e => nav('/sermons', e)} href="/sermons" class="nav-link${active('/sermons')}">Sermons</a>
      </li>

      <li class="nav-list-item">
        <span class="nav-link hover-nav-link${active('/about')}">
          <i>About Us</i>

          <ul class="hover-nav">
            <li>
              <a disabled href="/about/about-us" class="nav-link${active('/about/about-us')}">About Us</a>
            </li>

            <li>
              <a disabled href="/about/our-constitution" class="nav-link${active('/about/our-constitution')}">Our Constitution</a>
            </li>

            <li>
              <a disabled href="/about/music-policy" class="nav-link${active('/about/music-policy')}">Music Policy Statement</a>
            </li>

            <li>
              <a disabled href="/about/contact-us" class="nav-link${active('/about/contact-us')}">Contact Us</a>
            </li>
          </ul>
        </span>
      </li>

      <li class="nav-list-item">
        <a @click=${e => nav('/giving', e)} href="/giving" class="nav-link${active('/giving')}">Giving</a>
      </li>
    </ul>
  </div>
`;

const NavbarButton = () => html`
  <div class="content">
    <button
      aria-label="Menu Button"
      @click="${() => {
        isMenuOpen = !isMenuOpen;
        update();
      }}"
      class="menu-button${isMenuOpen ? ' open' : ''}"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
`;

let matches = window.innerWidth <= 1285;
export const Navbar = () => {
  matchMediaMaxWidth(1285, (m: boolean) => {
    matches = m;
    isMenuOpen = false;
    console.log(m);
    update();
  });

  return html`
    <nav class="navbar">
      <a href="/" class="logo" title="Christ Church Horison">
        <div class="image">
          <img desktop src="/logo-text.svg" alt="CCH Logo" />
          <img mobile src="/logo-text-mobile.svg" alt="CCH Logo" />
        </div>
        <!-- <div class="label">Christ Church Horison</div> -->
      </a>

      ${!matches ? NavbarContent() : NavbarButton()}
    </nav>

    ${!!matches
      ? html`
          <div
            @click="${() => {
              isMenuOpen = false;
              update();
            }}"
            class="cdk-menu-backdrop${isMenuOpen ? ' open' : ''}"
          ></div>
          <menu class="navbar-option-menu${isMenuOpen ? ' open' : ''}">
            ${NavbarContent()}
          </menu>
        `
      : ''}
  `;
};

