import { html } from 'lit-html';
import './giving.scss';

export const giving = () => {
  return html`
    <section class="giving-page">
      <header class="giving-header">
        <div class="inner">
          <h2>Giving</h2>
          <p>How you can give towards the work at Christ Church Horison</p>
        </div>
      </header>
    
      <section class="content-section">
        <h5>Giving to the Work at CCH</h5>
    
        <p>
          The work at Christ Church Horison is entirely dependant on the giving of the church family. We strongly believe in
          the principle of <strong>2 Corinthians 9:7</strong>, that each should give according to his own means under no
          compulsion. We do take a weekly offering during our Sunday services, but we also have a bank account that you can
          make donations into.
        </p>
      </section>
    
      <section class="content-section">
        <h5>What We Use the Money For</h5>
    
        <p>
          Church work is not done for profit and all the money that we receive through tithes and donations goes towards
          furthering the work of the Kingdom within our congregation. The usage of our funds is kept transparent and is
          reported to the congregation at our yearly AGM.
        </p>
      </section>
    
      <section class="content-section">
        <h5>How You Can Donate</h5>
    
        <p>
          Here are some ways in which you can give towards church work.
        </p>

        <br />
    
        <h6>Sunday service</h6>
    
        <p>Every Sunday morning, we take up a collection for those who prefer to give physically.</p>
    
        <br />
    
        <h6>Online EFTs</h6>
    
        <ul class="bank-details">
          <li>
            <p><strong>Bank</strong> Standard Bank</p>
          </li>

          <li>
            <p><strong>Branch code</strong> 051001</p>
          </li>

          <li>
            <p><strong>Account Number</strong> 21148643</p>
          </li>

          <li>
            <p><strong>Account Holder</strong> Christ Church Horison</p>
          </li>
        </ul>
      </section>
    </section>
  `;
};
