import { html } from 'lit-html';
import './schedule.scss';
import { AlertCard } from '../../components/alert/alert';

export const schedule = () => {
  return html`
    <section class="schedule-page">
      <header class="schedule-header">
        <div class="inner">
          <h2>Our Schedule</h2>
          <p>Times and information about our services, Bible studies, and prayer meetings.</p>
        </div>
      </header>
    
      <article class="times-list">
        <div class="times-table">
          <nav class="table-header">
            <div class="title">Sunday Services</div>
          </nav>
    
          <div class="info">
            <p>
              Our Sunday services are held at our church building: 14 Cutten St, Horison, Roodepoort, 1724. You can find
              more information about our sermon recordings
              <a routerLink="/sermons">here</a>.
            </p>
          </div>
    
          <div class="info">
            <div class="row">
              <div class="col">Morning Service</div>
              <div class="col">Every Sunday - 9:30AM</div>
            </div>
            <div class="row">
              <div class="col">Evening Service</div>
              <div class="col">Every Sunday - 5:30PM</div>
            </div>
          </div>
        </div>
    
        <div class="times-table">
          <nav class="table-header">
            <div class="title">Bible Studies</div>
          </nav>
    
          <div class="info">
            <p>
              We have a few Bible Studies throughout the week. Please <a href="/about/contact-us">contact us</a> for more
              information.
            </p>
          </div>
    
          <div class="info">
            <div class="row">
              <div class="col">Adult Sunday School</div>
              <div class="col">Sunday - 08:30AM</div>
            </div>
    
            <div class="row">
              <div class="col">Ladies Bible Study</div>
              <div class="col">Wednesday - 10:00AM</div>
            </div>
    
            <div class="row">
              <div class="col">Wednesday Bible Study (Florida Park)</div>
              <div class="col">Wednesday - 7:00PM</div>
            </div>
    
            <div class="row">
              <div class="col">Thursday Bible Study (Midrand)</div>
              <div class="col">Thursday - 7:00PM</div>
            </div>
          </div>
        </div>
    
        <div class="times-table">
          <nav class="table-header">
            <div class="title">Prayer Meetings</div>
          </nav>
    
          <div class="info" style="text-decoration: none !important">
            <p>Our Prayer meetings are held at our church building just before the evening service. Please come and join us
              as we lift our prayers, petitions, and intercessions to the Lord.</p>
          </div>
    
          <div class="info">
            <div class="row">
              <div class="col">Sunday Afternoon Prayer Meeting</div>
              <div class="col">Sunday - 04:45PM</div>
            </div>
          </div>
        </div>
    
        <div class="times-table">
          <nav class="table-header">
            <div class="title">Youth Programs</div>
          </nav>
          <div class="info">
            <p>
              Every Friday, expect during School Holidays, we hold youth events at our premises. These youth meetings are
              for students between Grade 1 and Grade 12, with separate meetings for
              High-Schoolers and Primary School students.
            </p>
          </div>
    
          <div class="info">
            <div class="row">
              <div class="col">Junior Youth Group (GR1 - GR7)</div>
              <div class="col">Fridays - 3:00PM</div>
            </div>
            <div class="row">
              <div class="col">Senior Youth Group (GR8 - GR12)</div>
              <div class="col">Fridays - 6:00PM</div>
            </div>
          </div>
        </div>
      </article>
    </section>
  `;
};
