import { NavDef } from './models/nav';
import { home } from './pages/home/home';
import { schedule } from './pages/schedule/schedule';
import { aboutUs } from './pages/about/about-us/about-us';
import { contactUs } from './pages/contact-us/contact-us';
import { Helmet } from './lib/helmet';
import { ourConstitution } from './pages/about/our-constitution/our-constitution';
import { ourMusicPolicy } from './pages/about/music-policy/music-policy';
import { sermonsv2 } from './pages/sermon/sermon.v2';
import { subscribe } from './pages/subscribe/subscribe';
import { giving } from './pages/giving/giving';

Helmet.baseTitle = 'Christ Church Horison | ';

export const routes: NavDef[] = [
  {
    path: '',
    page: () => home(),
    helmet: new Helmet({
      title: 'Home',
      description: `God's family or redeemed families in ministry. Corner of Cutten and Raath Street.`,
    }),
  },
  {
    path: 'schedule',
    page: () => schedule(),
    helmet: new Helmet({
      title: 'Our Schedule',
      description: 'Find the times and locations of our various study groups and meetings.',
    }),
  },
  {
    path: 'about-us',
    page: () => aboutUs(),
    helmet: new Helmet({
      title: 'About Christ Church Horison',
      description: 'Find out about our constitution and beliefs.',
    }),
  },
  {
    path: 'about/about-us',
    page: () => aboutUs(),
    helmet: new Helmet({
      title: 'About Christ Church Horison',
      description: 'Find out about our constitution and beliefs.',
    }),
  },
  {
    path: 'about/our-constitution',
    page: () => ourConstitution(),
    helmet: new Helmet({
      title: 'About Us | Our Constitution',
      description: 'Find out about our constitution and beliefs.',
    }),
  },
  {
    path: 'about/music-policy',
    page: () => ourMusicPolicy(),
    helmet: new Helmet({
      title: 'About Us | Our Music Policy',
      description: 'Read our music policy statement',
    }),
  },
  {
    path: 'about/contact-us',
    page: () => contactUs(),
    helmet: new Helmet({
      title: 'Get In Touch',
      description: 'Contact our minsters and elders for more information about CCH.',
    }),
  },
  {
    path: 'sermons',
    page: () => sermonsv2(),
    helmet: new Helmet({
      title: 'Sermon Downloads',
      description: 'Download and Listen to our latest sermons.',
    }),
  },
  {
    path: 'giving',
    page: () => giving(),
    helmet: new Helmet({
      title: 'Giving to Christ Church Horison',
      description: 'How you can give towards the work at Christ Church Horison.',
    }),
  },
  {
    path: 'subscribe',
    page: () => subscribe(),
    helmet: new Helmet({
      title: 'Subscribe to our newsletter',
      description: 'Receive our latest articles directly to your news box',
    }),
  },
  {
    path: `**`,
    redirectTo: '/',
  },
];

