import { html, render } from 'lit-html';
import { useNavigation } from './lib/nav';
import { routes } from './routes';
import { Navbar } from './components/navbar/navbar';
import { crawlAnchors } from './lib/anchors';
import { getYear } from './lib/year';

// Get the place in index.html where we put the app
const root = document.getElementsByTagName('app-root')[0];

const app = () => html`
  ${Navbar()}

  <main class="main">${useNavigation(routes)}</main>

  <div class="notice-banner">
    We've released our sermons as a podcast. Find them
    <a href="/sermons">on our site</a> or at
    <a
      target="_blank"
      rel="noopener"
      href="https://anchor.fm/christchurchhorison"
    >
      anchor.fm
    </a>
  </div>

  <footer class="footer">
    <div class="info">
      <ul class="sitemap">
        <li>
          <a href="/">Home</a>
        </li>

        <li>
          <a href="/schedule">Schedule</a>
        </li>

        <li>
          <a href="/sermons">Sermons</a>
        </li>

        <li>
          <a href="/about/contact-us">Contact Us</a>
        </li>

        <li>
          <a href="/about-us">About Us</a>
        </li>

        <li>
          <a href="/giving">Giving</a>
        </li>

        <li>
          <a href="https://engaged.christchurchhorison.co.za">
            Resources
          </a>
        </li>
      </ul>
      <a href="https://goo.gl/maps/6fNaxWwUEe9dTFpTA" class="map">
        <div class="image">
          <img src="/map.jpeg" alt="Location Map" />
        </div>
        <address class="address-label">
          14 Cutten St, Horison, Roodepoort, 1724
        </address>
      </a>
    </div>
    <div class="copyright">&copy; Christ Church Horison | ${getYear()}</div>
  </footer>
`;

export function update(): void {
  render(app(), root);
  crawlAnchors('routerLink');
}

update();
