import { html, TemplateResult } from 'lit-html';
import './music-policy.scss';

export const ourMusicPolicy = () => {
  return html`<article class="about">
    <header class="about-header">
      <div class="inner">
        <h2>Music Policy</h2>
      </div>
    </header>

    <section class="content-section">
      <p>
        Herein is this Church’s official policy in regards to the choosing,
        content, style and presentation of music within our corporate worship.
        These are the collected thoughts of this Church's council and are based
        on the scripture of God to the best of the councils’ understanding. This
        Church’s council holds themselves as responsible to God for what is done
        within the congregation and seek to do His will first and foremost. What
        follows is the formalised, official statement regarding the music policy
        of Christ Church Horison.
      </p>
    </section>

    <section class="content-section">
      <h5>Regarding the Selection of Music</h5>

      <p>
        The council of this Church recognises the Biblical requirement to
        worship God with various songs and hymns during corporate worship
        (Ephesians 5:19, Psalm 100). We recognise the deep and rich history of
        ancient hymns and tunes that have been preserved through generations of
        Christian liturgy, but also acknowledge the beautiful scriptural and
        doctrinal soundness of modern hymn writers. We reject the idea that only
        old hymns can be sung in corporate worship as much as we reject the idea
        that only the new can be sung. We therefore outline the following
        requirements for hymns that are sung within worship. A) They must be
        theologically sound. Not all hymns need to explicitly cover every tenet
        of Christian doctrine, but the hymns that we sing must reflect the
        truths of scripture accurately and clearly. B) Hymns must show reverence
        and awe for the God we worship, should not be shallow, flippant, or
        needlessly repetitive. C) They must reflect our joy in the Lord and in
        our salvation (Psalm 98:4). D) We sing to encourage one another in the
        Lord therefore, the songs must be unifying, edifying, athemnal and
        conducive to being sung by the whole congregation. This limits us from
        styles of music that are performative in nature. E) Hymns must be
        appropriate for the occasion of worship and the theme of the service. We
        therefore place no stipulation on how many old or new hymns are sung.
      </p>

      <p>
        Further, we do not find any valid reason for us to restrict particular
        themes of music to certain times of the year (eg. carols to
        Christmastime and resurrection hymns to Easter). Rather we encourage our
        musicians to select music that best helps the congregation of our church
        to worship God. We recommend that the music we sing during our services
        takes the congregation from general affirmations, confessions, and
        worship of God (in the opening few hymns) to more specific and thematic
        hymns (at the end of the service).
      </p>
    </section>

    <section class="content-section">
      <h5>Regarding the Introduction of New Hymns</h5>

      <p>
        As we stated earlier in this document, we do not distinguish between
        hymns based primarily on their age, but rather consider the theological
        and appropriateness of those hymns in deciding whether or not they can
        be sung within the worship services at Christ Church Horison. That being
        said, it is the responsibility of the church’s council to ensure that
        the content of the music sung in our church conforms to these
        restrictions.
      </p>

      <p>
        At this point, once a new hymn has been approved, the musicians will set
        a date when that hymn is to be sung; record of this will be kept on a
        spreadsheet that everyone has access to. It will then be the
        responsibility of the musicians to help and introduce that new hymn to
        the members of the congregation. This could be by sending a youtube link
        on the Church WhatsApp group, by playing the tune during services
        (before the service, during collection or communion, or after the
        service), or by organising to have a singalong session before or after
        the evening service.
      </p>
    </section>
  </article>`;
};
