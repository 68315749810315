import { html } from 'lit-html';
import './subscribe.scss';
import { ContactCard } from '../../components/contact-card/contact-card';

export class DefaultRequestParameters implements RequestInit {
  public headers = { 'Content-Type': 'application/json' };
  public method = 'GET';

  constructor(init?: RequestInit) {
    Object.assign(this, init);
  }
  public toObject(): RequestInit {
    return this as any;
  }
}

export const subscribe = () => {
  return html`
    <section class="subscribe-page">
      <header class="subscribe-header">
        <div class="inner">
          <h2>Subscribe</h2>
          <p>Subscribe to our news list to receive our latest articles and resources right in your mailbox</p>
        </div>
      </header>

      <article class="subscribe-form">
        <form
          @submit="${async (event: SubmitEvent) => {
            event.preventDefault();

            const host = event.target as HTMLFormElement;
            host.classList.add('loading');

            const data = new FormData(host);

            const body = {
              email: data.get('email'),
              name: data.get('name'),
            };

            const params = new DefaultRequestParameters({ method: 'POST', body: JSON.stringify(body), mode: 'cors' });
            const response = await fetch('https://christ-church-horizon.herokuapp.com/api/v2/newsletter/subscribe', params.toObject());
            const responseText = await response.text();

            if (responseText === 'DONE') {
              host.outerHTML = `<h4>You have been subscribed</h4>`;
            } else if (responseText === 'ALREADY') {
              host.outerHTML = `<h4>You have already been subscribed</h4>`;
            } else host.classList.remove('loading');
          }}"
        >
          <div class="form-control">
            <input name="email" placeholder="Email" type="email" required />
          </div>

          <div class="form-control">
            <input name="name" placeholder="Full name" type="text" required />
          </div>

          <div class="form-control button">
            <button>Subscribe</button>
          </div>
        </form>
      </article>
    </section>
  `;
};

