export class Helmet {
  public static baseTitle: string;

  public description: string;
  public title: string;
  public cannonical: string;

  private get titleElement(): HTMLTitleElement {
    return document.head.getElementsByTagName('title')[0];
  }

  private get descriptionElement(): HTMLMetaElement {
    return document.getElementById('description_meta_tag') as any;
  }

  private get cannonicalElement(): HTMLLinkElement {
    return document.getElementById('cannonical_meta_tag') as any;
  }

  constructor(init: Partial<Helmet>) {
    Object.assign(this, init);
  }

  public apply(): void {
    this.titleElement.innerHTML = `${Helmet.baseTitle} ${this.title}`;

    if (this.description) {
      if (!!this.descriptionElement) {
        this.descriptionElement.content = this.description;
      } else {
        const meta = document.createElement('meta') as HTMLMetaElement;
        meta.name = `description`;
        meta.content = this.description;
        meta.id = `description_meta_tag`;
        document.head.appendChild(meta);
      }
    } else this.descriptionElement && this.descriptionElement.remove();

    if (this.cannonical) {
      if (!!this.cannonicalElement) {
        this.cannonicalElement.href = this.cannonical;
      } else {
        const meta = document.createElement('link') as HTMLLinkElement;
        meta.rel = `canonical`;
        meta.href = this.cannonical;
        meta.id = `cannonical_meta_tag`;
        document.head.appendChild(meta);
      }
    } else this.cannonicalElement && this.cannonicalElement.remove();
  }
}
