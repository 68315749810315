import { html, TemplateResult } from 'lit-html';
import './our-constitution.scss';
import { getBiblereference } from '../../../lib/getBibleReference';

export const ourConstitution = () => {
  function createTemplate(passage: string): TemplateResult {
    return getBiblereference(passage, 'anchor') as any;
  }

  return html`<article class="about">
    <header class="about-header">
      <div class="inner">
        <h2>Our Constitution</h2>

        <p>
          Below is the constitution of Christ Church Horison. This constitution
          is a reflection of the practices and beliefs of CCH's council. We
          believe that the word of God is final in all matters, making this
          document subject to discretion as the council deems appropriate.
        </p>
      </div>
    </header>

    <section class="content-section">
      <h5>1. To the Glory of God</h5>

      <p>
        The Congregation of Christ Church Horison, is a constituent church of
        the REACH SA, has subscribed to the Constitution of, and has been
        declared a constituent by the aforementioned organization.
      </p>
    </section>

    <section class="content-section">
      <h5>2. Legal Status</h5>

      <p>
        Christ Church Horison, (hereinafter referred to as “this Church”, shall,
        in terms of the Secular Law, be a voluntary Association with full legal
        personality, with perpetual succession and power to hold property in its
        own name, distinct from its members, and capable of being sued and suing
        in its own name.
      </p>
    </section>

    <section class="content-section">
      <h5>3. Objects</h5>

      <p>
        The aims and objects of this Church shall be to provide for the worship
        of God, in terms of the faith and doctrine as laid down in the
        Constitution of REACH SA .
      </p>
    </section>

    <!-- Membership -->
    <section class="content-section">
      <h5>4. Membership</h5>

      <p>
        The members of this church shall be all persons who have applied to the
        Church Council for membership and who have been admitted as such,
        provided that the Church Council shall not admit any person to
        membership unless he:
      </p>

      <div class="notice-block">
        <ul>
          <li>
            <p>has been baptized,</p>
          </li>

          <li>
            <p>
              has accepted and continues to accept the Constitution of REACH SA,
            </p>
          </li>

          <li>
            <p>is over eighteen years of age,</p>
          </li>

          <li>
            <p>is not a member of any other Church,</p>
          </li>

          <li>
            <p>
              has been a habitual worshipper in this Church for a period of not
              less than six months.
            </p>
          </li>
        </ul>
      </div>

      <p>
        A member of REACH SA, upon transfer to this Church, shall be received as
        a member of this Church, if he applies for admission as such.
      </p>

      <p>
        No person shall be entitled to vote at any Vestry meeting, or be
        eligible as holder of any office and/or as member of any body or council
        constituted by this Constitution, unless he or she is a member.
      </p>
    </section>

    <section class="content-section">
      <h5>5. Forfeiture of Membership</h5>

      <p>
        The Church Council shall delete the name of a member from the Membership
        Register if it is, after giving notice by registered post to such
        member, of its intention to do so, satisfied that the member concerned
        no longer qualifies to be a member, unless the member has ceased to be
        an habitual worshipper on account of age or ill-health or any other
        similar valid reason. If a member is not satisfied with any decision of
        the Church Council concerning himself, he may ask the Executive
        Committee of Synod to mediate or arbitrate in terms of Article 7(3)(f)
        of the Constitution of REACH SA. Such arbitration shall be subject to
        confirmation by the next Annual Synod whose decision shall be final.
      </p>
    </section>

    <section class="content-section">
      <h5>6. Membership Register</h5>

      <p>
        It shall be the duty of the Church Council to keep at all times a
        Membership Register.
      </p>

      <p>
        The Church Council shall from time to time and at least once annually,
        not later than 7 days before the date of the Annual Vestry Meeting,
        bring the Membership Register up to date by deleting the names of
        persons who have ceased for any reason to be members, and adding new
        members’ names.
      </p>

      <p>
        The Membership Register shall be open for inspection by any member of
        this Church.
      </p>
    </section>

    <section class="content-section">
      <h5>7. Enrolment as a Member</h5>

      <p>
        Any person who desires to be enrolled as a member shall apply for such
        membership on the official application form as set out in “Annexure A”
        hereto.
      </p>

      <p>
        The Church Council shall at its first meeting after receipt of a duly
        completed application form, consider such application for enrolment as a
        member and shall forthwith enroll the Applicant or refuse the
        application without being obliged to furnish any reason for such
        refusal.
      </p>
    </section>

    <section class="content-section">
      <h5>8. The Rights of a Member</h5>

      <p>
        All the Members of this Church shall be entitled to attend Vestry
        Meetings and to speak thereat.
      </p>

      <p>
        No persons shall be entitled to vote at any Vestry Meeting unless his or
        her name appears on the membership Register as at the date of the
        Meeting. In the case of the Annual Vestry Meeting, his or her name must
        appear on the Membership Register at least 7 days before the date of
        that Meeting.
      </p>
    </section>

    <section class="content-section">
      <h5>9. Vestry Meetings</h5>

      <p>
        A general meeting of members shall be held once in every year in the
        month of October or November and shall be called the Annual Vestry
        Meeting.
      </p>

      <p>
        Other Vestry Meetings may be held from time to time as hereinafter
        provided for and shall be called Special Vestry Meetings.
      </p>
    </section>

    <section class="content-section">
      <h5>10. Quorum Limitations</h5>

      <p>
        The quorum for any Vestry Meeting shall be a 1/3 of the Membership.
        Failing a Quorum, the meeting shall be adjourned for not more than 30
        days. When the second meeting is called, the Quorum shall then be 20% of
        the membership, but never less than 12 members.
      </p>
    </section>

    <section class="content-section">
      <h5>11. Convening of Vestry Meetings</h5>

      <p>
        The Church Council shall determine the date, time and venue of the
        Annual Vestry Meeting and shall give notice thereof, by announcement, on
        two consecutive Sundays beforehand and either by affixing a written
        notice, signed by the Chairman of the Church Council, at least fifteen
        days beforehand, in a prominent place in the Church precincts, or by
        registered post to all members at the last known address, as shown in
        the membership register.
      </p>

      <p>
        The Church Council shall convene a Special Vestry Meeting and give
        notice thereof in the same manner as is prescribed in the immediately
        preceding sub-article, on receipt of a request for such meeting signed
        by at least five Members, or by the Rector of the Congregation, or by
        the Churchwardens. The Church Council itself may decide to call a
        Special Vestry Meeting. The date of a Special Vestry Meeting shall not
        be later than four weeks after the date of the request for such meeting.
      </p>

      <p>
        The notices of Vestry Meetings shall specify the business to be
        transacted at the Meetings and it shall not be competent for any Vestry
        Meeting to deal with any matter of which no notice has been given.
      </p>

      <p>
        Should any matter be dealt with at a Vestry Meeting concern any Member
        of this Church, the Church Council shall notify such member of the
        matter by registered post at least fourteen days before the date of the
        Meeting. The Member concerned shall be entitled to attend and speak
        thereat.
      </p>
    </section>

    <section class="content-section">
      <h5>12. Business of Vestry Meetings</h5>

      <p>
        The business to be transacted at the Annual Vestry Meeting shall be:
      </p>

      <div class="notice-block">
        <ol>
          <li>
            <p>The election of Members of the Church Council;</p>
          </li>

          <li>
            <p>The election of two Churchwardens;</p>
          </li>

          <li>
            <p>The election of delegates to the Synod;</p>
          </li>

          <li>
            <p>
              Receiving, considering and adopting the financial report, annual
              accounts and balance sheet;
            </p>
          </li>

          <li>
            <p>
              Receiving, considering and adopting the annual report of the
              Churchwardens;
            </p>
          </li>

          <li>
            <p>
              Any other matter placed on the Agenda either by the Church Council
              or by written notice from any member of this Church. Such written
              notice must reach the Secretary or Chairman of the Council at
              least four weeks before the Meeting.
            </p>
          </li>
        </ol>
      </div>

      <p>
        The method of the nomination and election of Members of the Church
        Council, Churchwardens and Sidesmen shall be as follows: Nominations
        must be proposed and seconded by members of this Church; The Chairman of
        the Vestry Meeting must satisfy himself that the nominated person is
        qualified and has consented to this nomination. The election shall be by
        ballot if the nominations exceed the number required.
      </p>
    </section>

    <section class="content-section">
      <h5>13. The Chairman Vestry Meetings</h5>

      <p>
        The Rector of the Congregation shall preside at all Vestry Meetings and
        if absent, an Acting Chairman shall be elected from those present. The
        Chairman shall be entitled to a casting vote only.
      </p>
    </section>

    <section class="content-section">
      <h5>14. The Minutes of the Proceedings of Vestry Meetings</h5>

      <p>
        The Secretary of the Church Council shall keep Minutes of the
        proceedings of Vestry Meetings, which Minutes shall be required to be
        approved by the next Vestry Meeting and to be signed by the Chairman.
      </p>
    </section>

    <section class="content-section">
      <h5>15. The Church Council</h5>

      <p>
        There shall be a Church Council for the proper administration of the
        affairs of the Church. Such a council will consist of:
      </p>

      <div class="notice-block">
        <ul>
          <li>
            <p>
              The Rector and any other Ministers of the Congregation who shall
              be ex officio Member(s).
            </p>
          </li>

          <li>
            <p>The two Churchwardens, who shall be ex officio Members.</p>
          </li>

          <li>
            <p>
              A maximum of eight, but a minimum of four other Members who shall
              be elected by the Annual Vestry Meeting.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section class="content-section">
      <h5>16. Qualification for Membership of Church Council</h5>

      <p>
        Only Members of this Church shall be entitled to be Members of the
        Church Council.
      </p>
    </section>

    <section class="content-section">
      <h5>17. Chairman and Office Bearers</h5>

      <p>
        The Rector shall be the Chairman of the Church Council and in his
        absence the Council may elect a Chairman from amongst themselves for
        that particular Meeting.
      </p>

      <p>The Chairman shall have a casting vote only.</p>

      <p>
        The Council shall elect from among itself a Secretary and a Treasurer.
      </p>

      <p>
        Cheques and other documents relating to finance shall require two
        signatures, one of which to be that of the Treasurer or his deputy. Two
        alternate Council Members with signing powers shall be appointed.
      </p>

      <p>
        Any Member of the Church Council shall be permitted to hold more than
        one office, if so elected.
      </p>
    </section>

    <section class="content-section">
      <h5>18. Election of Church Wardens</h5>

      <p>The two Churchwardens shall be elected as follows:</p>

      <p>
        One shall be elected from Members nominated for that Office by Members
        other than the Clergy at the Annual Vestry Meeting, and shall be known
        as “the People’s Warden”.
      </p>

      <p>
        One shall be nominated for election to that Office by the Rector and
        shall be known as “the Rector’s Warden”.
      </p>

      <p>
        A Candidate must receive more than 50% of the votes of those present. If
        there is only a single candidate for the office of People’s Warden, he
        must receive more than 50% of the votes of those present. If he fails to
        qualify, a further Candidate must be nominated. If there be several
        candidates for the office of People’s Warden and no one candidate
        obtains more than 50% of votes, then there shall be further ballots for
        those two candidates who obtain the highest and next highest number of
        votes in the first ballot until one or other of such candidates obtains
        the necessary votes.
      </p>

      <p>
        If the person nominated for Rector’s Warden fails to obtain the
        necessary number of votes, then further nominations shall be made until
        the necessary number of votes is obtained.
      </p>

      <p>
        A vacancy in the office of Churchwarden occurring during the year of
        office may be filled at a Special Vestry Meeting. Candidates for the
        office are entitled to vote.
      </p>
    </section>

    <section class="content-section">
      <h5>19. The Duties of Churchwardens</h5>

      <p>
        The Churchwardens shall be jointly responsible for carrying out the
        instructions of the Council relative to the control of the affairs of
        this Church, and shall have power to require the various services, and
        the Sacraments, to be celebrated in accordance with the Standards and
        Formularies of REACH SA and to prevent and prohibit the introduction of
        any Ceremony, Vestment, Fitting or Ornament which is unlawful in the
        Church of England Prayer Book of 1662. The Churchwardens are bound to
        act on a complaint made in writing under this clause by at least five
        members of this Church, but may refer such complaint to the Council for
        its instructions.
      </p>
    </section>

    <section class="content-section">
      <h5>20. Declaration of Loyalty by Office Bearers</h5>

      <p>
        Before entering upon his duties, every Churchwarden, and Member of the
        Church Council shall solemnly assent to and subscribe his name to the
        following Declaration of Loyalty before two witnesses:
      </p>

      <p style="padding-left: 35px">
        "I <i>[Full Name]</i> solemnly promise to adhere to the Constitution of
        REACH SA, and to receive as authoritative the standards of faith and
        doctrine, all forms of service and discipline of the Church in
        accordance with the Constitution and all subsequent amendments thereto.
        I further solemnly promise to acknowledge the authority and to carry out
        the decisions of Synod and the lawful instructions of the officers of
        the Church, and to work for the peaceful and Christian fellowship of the
        Church, its Clergy, Synod members and Constituent Churches. I further
        solemnly declare that if at any time hereafter, while holding office in
        the Church, I consent to any agreement of compromise with any other
        Church or admit the authority or accept the administration of a Bishop
        or other person who does not hold office in REACH SA, I shall resign
        from REACH SA and any of its Constituent Churches within thirty days of
        being called upon to do so by the Synod or the Bishop or the
        Vicar-General or the Chancellor or the Registrar or by the majority vote
        of the Council or congregation of the Constituent Church to which I
        belong, and shall vacate and return any property of REACH SA or any of
        its constituent churches which I may be occupying or holding, whether
        officially or personally, within the aforesaid period of thirty days."
      </p>
    </section>

    <section class="content-section">
      <h5>21. Period of Office for Elected and Appointed Members</h5>

      <p>
        The period of office of the elected Members of the Church Council shall
        be from date of the Annual Vestry Meeting upon which they have been
        elected to the date of the next Annual Vestry Meeting; The period of
        office of appointed Members shall expire after the election of a new
        Council at the next Annual Vestry Meeting.
      </p>
    </section>

    <section class="content-section">
      <h5>22. Functions and Power of the Church Council</h5>

      <p>
        It is the function of the Church Council to conduct and administer the
        affairs of this Church and it has the following powers (subject always
        to the Constitution of REACH SA):
      </p>

      <p>
        To appoint Ordained Ministers or duly qualified laymen as Rector or
        Minister or Lay-Minister to the Congregation, to terminate such
        appointments and to enter into contracts with persons as appointed
        relative to their tenure of office and other matters;
      </p>

      <p>
        To provide the requirements for divine service and to acquire and
        maintain property, movable and/or immovable for that purpose;
      </p>

      <p>To make and/or authorise all normal expenditure and payment;</p>

      <p>
        To open or cause to be opened current banking accounts or savings
        accounts; to raise or borrow money from time to time by means of an
        overdraft or loans against security or by passing a mortgage over the
        property of this Church or in any other way as the Church Council may
        deem fit; documents and cheques to be signed by two persons;
      </p>

      <p>
        To invest, lend, put out to interest, place on deposit, advance or
        otherwise deal with moneys not immediately required to meet current
        charges upon this Church and in the entire discretion of the Church
        Council from time to time, to realize, vary, re-invest, or otherwise
        deal with any such investments and securities, provided that none of the
        powers contained herein may be exercised unless authorized by a
        resolution of the Church Council passed by a majority of 60% of those
        elected and entitled to vote at a proper Meeting of the Church Council;
      </p>

      <p>
        Subject to the provisions of Article 25 hereof to purchase or to sell
        immovable property;
      </p>

      <p>
        To prepare or cause to be prepared an annual Financial Statement
        covering the financial year ending on 31st March of each year, including
        a Revenue and Expenditure Account and Balance Sheet, certified as
        correct by the Treasurer or Auditor for submission to the Annual Vestry
        Meeting;
      </p>

      <p>
        To keep and maintain the Membership Register of this Church and such
        other Register as may be required;
      </p>

      <p>
        To arrange the basis on which Sustentation Funds or other sources of
        revenue shall be provided;
      </p>

      <p>
        To cause proper Minutes and Records of its proceedings and of all Vestry
        Meetings and of transactions of this Church, to be kept;
      </p>

      <p>
        To set up and establish standing and/or ad hoc committees and to
        delegate power to such committees;
      </p>

      <p>
        A Standing Committee consisting of two Churchwardens, Secretary and
        Treasurer and Rector shall be empowered to deal with any urgent matter
        or business between Council Meetings. Their decisions to be reported and
        minuted at the next Council Meeting. (unanimous decision of committee
        required);
      </p>

      <p>
        To decide on the following special matters, namely - Allegations of
        heresy or misconduct against the Rector or a Minister or a Lay-Minister;
        The resignation of the Rector or a Minister or a Lay-Minister;
        Complaints relative to the conduct of any office-bearer of this Church;
        The interpretation of this Constitution and Rules of Procedure.
      </p>

      <p>
        Provided that no resolution on these matters shall be carried unless
        passed by a majority of 6o% of those elected and entitled to vote at a
        proper Special Meeting of the Church Council.
      </p>
    </section>

    <section class="content-section">
      <h5>23. Meeting of the Church Council</h5>

      <p>
        The Church Council shall meet regularly, at least once every 2nd
        calendar month;
      </p>

      <p>
        Special Meetings of the Church Council shall take place at any time;
      </p>

      <p>
        Special Meetings of the Church Council shall be convened by the
        Secretary;
      </p>

      <p>
        Special Meetings may be convened on the decision of the Church Council
        or at the request of the Rector or any three Members of the Council and
        it shall be the duty of the Secretary to convene the Special Meeting,
        within seven days from the date on which he/she has received the
        Council’s instructions or the request of the Rector or three Members;
      </p>

      <p>
        All matters other than those specified in Section 21, shall be decided
        by a majority of votes.
      </p>
    </section>

    <section class="content-section">
      <h5>24. Quorum of Church Council Meetings</h5>

      <p>
        The Quorum for meetings of the Church Council shall be FIVE (5) Members
        plus the Chairman.
      </p>
    </section>

    <section class="content-section">
      <h5>25. Forfeitures of Seats on the Church Council</h5>

      <p>
        If any Member absents himself from three consecutive Ordinary Meetings
        of the Church Council without leave, the Council may declare that such
        Member has forfeited his seat on the Church Council.
      </p>
    </section>

    <section class="content-section">
      <h5>26. Immovable Property</h5>

      <p>
        No immovable property shall be purchased or sold unless sanctioned by a
        resolution passed at a Vestry Meeting, by not less than 80% of those
        present and entitled to vote. All immovable property of this Church
        shall be registered in the name of the Central Trustees of REACH SA; All
        Deeds or other Documents necessary to obtain or give transfer of
        immovable property shall be signed by two members of the Church Council
        who shall be designated for that purpose by the Church Council. Title
        Deeds and other legal documents are to be placed in Safe Custody.
      </p>
    </section>

    <section class="content-section">
      <h5>27. Amendments and the Adoption of Constitutional Changes</h5>

      <p>
        Amendments of the Constitution and the Rules of Procedure shall only be
        made at Vestry Meetings. Such amendments shall only be valid: if at
        least 60% of the Church Membership is present; if passed by a majority
        of 80% of those present and entitled to vote; if not in conflict with
        the Constitution of REACH SA and Appendix thereto.
      </p>

      <p>
        Compulsory clauses incorporated in this Constitution in accordance with
        the Appendix to the Constitution of REACH SA, may not be amended unless
        the said Appendix is amended, and must be amended, if the Appendix is
        amended. These clauses appear in the Constitution:
      </p>

      <p>
        as the entire sections under the headings: To the Glory of God, Legal
        Status, Membership, Forfeiture of Membership, Declaration of Loyalty by
        Office Bearers
      </p>

      <p>as paragraph (a) under the heading Immovable Property</p>
    </section>
  </article>`;
};
