import { html } from 'lit-html';
import './sermon.scss';
import { until } from 'lit-html/directives/until';
import { cache } from 'lit-html/directives/cache';
import { loader } from '../../components/loader/loader';
import { ISermonResults } from './sermon.models.v2';
import { PodcastCard } from '../../components/podcast-card/podcast-card';

function getRSSFeed(): Promise<ISermonResults> {
  return fetch('https://christ-church-horizon.herokuapp.com/api/v2/sermons').then(d => d.json());
}

export const sermonsv2 = () => {
  const data = getRSSFeed().then(result => {
    return html`
      <header class="sermons-header">
        <div class="inner">
          <h2>Online Sermons</h2>
          <p>
            We provide recordings of our Sunday sermons on various platforms. You can view the live-streams of our sermons on YouTube every Sunday, or you can find audio recordings on Apple podcasts,
            Google podcasts, Spotify, and Anchor.fm
          </p>
        </div>
      </header>

      <article class="sermons">
        <div class="sermon-options">
          <a class="option" href="https://www.youtube.com/channel/UCEMc_OrxQdZL46-JL4XyhTA/videos" target="_blank" rel="noopener">
            <div class="icon">
              <svg viewBox="-21 -117 682.667 682">
                <path
                  d="M626.813 64.035c-7.375-27.418-28.993-49.031-56.407-56.414C520.324-6.082 319.992-6.082 319.992-6.082s-200.324 0-250.406 13.184c-26.887 7.375-49.031 29.52-56.406 56.933C0 114.113 0 217.97 0 217.97s0 104.379 13.18 153.933c7.382 27.414 28.992 49.028 56.41 56.41C120.195 442.02 320 442.02 320 442.02s200.324 0 250.406-13.184c27.418-7.379 49.032-28.992 56.414-56.406 13.176-50.082 13.176-153.934 13.176-153.934s.527-104.383-13.183-154.46zM256.21 313.915V122.022l166.586 95.946zm0 0"
                />
              </svg>
            </div>
            <div class="title">Watch on YouTube</div>
          </a>

          <a class="option" href="https://anchor.fm/christchurchhorison" target="_blank" rel="noopener">
            <div class="icon">
              <svg fill="none" viewBox="0 0 100 100">
                <path
                  d="M84.5428 38.2787s-3.1408-1.4928-6.4073-.5242c-2.4404.7212-5.1157 2.5746-6.6378 6.424-2.9353 7.3843-.3942 20.2617-1.388 20.2617-.9938 0-4.1219-11.8668-8.3068-24.2243-4.1848-12.3574-7.8036-25.1929-14.3366-24.1571-5.795.9141-3.9962 15.5484-1.7276 29.2561 2.0547 12.4245 4.4322 24.0565 3.0736 24.0565-2.4279.0042-10.395-34.3383-18.5592-33.0342-8.1642 1.3041 2.3524 39.1437.1426 39.215-1.8157.0587-6.2689-23.0334-12.8774-21.9725-5.0067.8051-.5451 18.7689-.3397 23.7798.0504 1.258.4403 3.6188-1.4424 3.6188H12v3.4929h6.1179c1.258-.0629 2.2518-.5451 2.755-1.7821 1.6982-4.1932-2.7634-22.006-1.7193-22.136.7171-.0881 2.2644 5.3337 4.4239 11.4684 2.1595 6.1347 4.3232 12.4833 8.4242 12.4833 8.8435 0-.1761-34.7535 1.5766-34.7535.9267 0 3.4552 7.0614 6.2354 14.8063 3.4007 9.4641 7.5478 19.9597 12.5545 19.9597 5.9753 0 4.0297-13.5567 2.1804-26.5473-1.6018-11.242-3.4803-21.8047-2.4782-21.8047 2.256 0 10.7724 48.3353 20.9997 48.3353 5.1241 0 5.1828-10.1057 5.1828-20.9997 0-7.6526-.6373-14.626 4.1597-16.3033 2.2811-.7967 4.5874.6165 4.5874.6165l-2.4572-9.5354Z"
                />
              </svg>
            </div>
            <div class="title">Listen on Anchor.fm</div>
          </a>

          <a class="option" href="https://open.spotify.com/show/48jRz6uD7z2VP4PPv0P5lk" target="_blank" rel="noopener">
            <div class="icon">
              <svg fill="none" viewBox="0 0 100 100">
                <path
                  fill-rule="evenodd"
                  d="M49.5 12c-9.9456 0-19.4839 3.9509-26.5165 10.9835C15.9509 30.0161 12 39.5544 12 49.5c0 9.9456 3.9509 19.4839 10.9835 26.5165C30.0161 83.0491 39.5544 87 49.5 87c9.9456 0 19.4839-3.9509 26.5165-10.9835C83.0491 68.9839 87 59.4456 87 49.5c0-9.9456-3.9509-19.4839-10.9835-26.5165C68.9839 15.9509 59.4456 12 49.5 12Zm17.1998 54.0861c-.1598.2626-.3698.4911-.618.6723-.2483.1813-.5299.3118-.8287.384-.2987.0722-.6088.0847-.9125.0368-.3036-.0479-.5948-.1553-.8568-.316-8.8071-5.3799-19.8858-6.5954-32.941-3.6145-.2991.0683-.6087.077-.9112.0256-.3024-.0513-.5918-.1618-.8516-.325-.2598-.1632-.4848-.3759-.6624-.6261-.1776-.2502-.3041-.5329-.3724-.832-.0683-.2991-.077-.6087-.0256-.9111.0513-.3025.1618-.5918.3249-.8516.1632-.2598.376-.4849.6262-.6624.2502-.1776.5329-.3041.832-.3724 14.2826-3.2678 26.5369-1.8611 36.42 4.1804 1.0999.6735 1.4466 2.1121.7771 3.212Zm4.5829-10.2099c-.4055.6598-1.0561 1.1318-1.8092 1.3126-.753.1808-1.547.0555-2.2078-.3482-10.0784-6.1969-25.445-7.9902-37.3685-4.3717-.7255.1721-1.4892.0611-2.1357-.3105-.6464-.3715-1.1268-.9755-1.3433-1.689-.2165-.7135-.1527-1.4826.1783-2.1507.331-.6681.9043-1.1849 1.603-1.4449 13.6212-4.1326 30.55-2.1281 42.1228 4.9814.3277.2008.6127.4643.8386.7753s.3882.6635.4778 1.0373c.0896.3738.1046.7616.0442 1.1412-.0604.3796-.1951.7436-.3962 1.0712l-.004-.004Zm.4025-10.6323c-12.0909-7.1772-32.0324-7.8387-43.5694-4.3318-.4427.1412-.9091.193-1.372.1524-.463-.0405-.9132-.1727-1.3247-.3889-.4114-.2161-.7757-.5118-1.0718-.87-.2961-.3581-.5181-.7716-.6531-1.2163-.1349-.4447-.1801-.9117-.133-1.3741.0472-.4623.1857-.9106.4077-1.3189.2219-.4083.5228-.7685.8851-1.0595.3623-.291.7789-.5071 1.2254-.6357 13.2426-4.025 35.2644-3.2439 49.1764 5.0133.8001.4746 1.3789 1.2475 1.6091 2.1488.2302.9013.0929 1.8572-.3816 2.6573-.4746.8-1.2476 1.3788-2.1489 1.609-.9013.2302-1.8571.0929-2.6572-.3816"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="title">Listen on Spotify</div>
          </a>
        </div>
        <div id="latest"></div>
      </article>

      <article class="latest-sermons">
        <ul>
          ${result.feed.map(item => {
            return html`<li>${PodcastCard(item, result.meta)}</li>`;
          })}
        </ul>
      </article>
    `;
  });

  return html` <section class="sermons-page">${cache(until(data, loader()))}</section> `;
};


