import { html, TemplateResult } from 'lit-html';
import './about-us.scss';
import { getBiblereference } from '../../../lib/getBibleReference';

export const aboutUs = () => {
  function createTemplate(passage: string): TemplateResult {
    return getBiblereference(passage, 'anchor') as any;
  }

  return html`<article class="about">
    <header class="about-header">
      <div class="inner">
        <h2>About Christ Church Horison</h2>
        <p>
          You can find more information about our doctrines and beliefs
          <a href="https://engaged.christchurchhorison.co.za/our-beliefs/statement-of-faith/">here</a>. If you would like more information about the denomination we belong to, visit
          <a target="_blank" rel="noopener" href="http://reachsa.org.za/about-us/"> the REACH-SA website </a>
        </p>
      </div>
    </header>

    <section class="content-section">
      <h5>A Brief Introduction</h5>
      <p>
        Hello. We are a close community of God’s people, who meet together regularly to worship and study our Saviour’s word. We love the Gospel of the Lord Jesus Christ through which we have been
        saved and are eager to share it with any who will listen! Come and join us.
      </p>
    </section>

    <section class="content-section">
      <p>
        So what is important to us? It is important to us that we honour God in our worship and life as a multi-cultural community. We seek to be reverent but joyful in our prayers and songs, and
        while we treasure traditional elements of praise, we remain relevant and modern. The Bible holds a prominent place in our hearts because we believe ardently that it is God’s infallible word
        and so we both teach it and regulate everything by it.
      </p>
    </section>

    <section class="content-section">
      <p>
        You will notice straight away that family is very important to us. We worship as families holding to the precious promises that God has made to christians and their households. We believe that
        Jesus, God’s Son, died and rose from the dead to save us and to restore what man originally lost in sinning against the Lord. That means as individuals we have communion with the triune God,
        and as households we serve the Lord.
      </p>
    </section>

    <section class="content-section">
      <p>
        We can sum up what is important to us: it is to live as
        <strong>God’s community of redeemed families in ministry</strong>.
      </p>

      <br />

      <h3>But, What Does That Mean?</h3>
    </section>

    <section class="content-section">
      <h5>We're God’s Community...</h5>

      <p>
        The church is God’s possession and design. God’s Son died to bring us back to Himself. God originally created us to love and worship Him and enjoy His presence but when Adam sinned rebellion,
        disobedience and hate for the Lord entered all our hearts. This is the root of all our troubles and broken lives. The Lord Jesus makes worshipers of us again.
      </p>

      <p>
        Our first great aim then must be to worship God as a multi-cultural group of people according to His commands and design. We are called to be a holy community, the word holy simply means set
        aside for God. (See ${createTemplate('Ephesians 2:14-22')})
      </p>
    </section>

    <section class="content-section">
      <h5>...Of Redeemed Families...</h5>

      <p>The Lord Jesus died to save us as individuals and each of us has as a responsibly to seek the Lord’s mercy in repentance and faith.</p>

      <p>
        The word redemption means to buy back. Jesus redeemed His people, dying at Calvary to pay for sin. He removes our guilt and sends the Holy Spirit to cleanse and change our hearts, restoring us
        to enjoy communion and worship of Him. God’s plan of redemption however also includes our families.
      </p>

      <p>
        God promised Abraham, the father of the faith, to be his God and the God of his children and God promises to bless families through the suffering of Jesus (${createTemplate('Psalm 22:27')}).
        It is no surprise that the New Testament starts with a record of the early church being formed and households being saved by the Lord.The Bible is filled with instruction to husbands, wives
        and children.
      </p>
    </section>

    <section class="content-section">
      <h5>...In Ministry.</h5>

      <p>
        When the Lord calls and saves us he gives each person gifts. He call us to end rebellion and serve the Lord and His people. (${createTemplate('1 Corinthians 12:12-31')},
        ${createTemplate('1 Peter 4:7-11')})
      </p>

      <p>We seek to offer ourselves to the Lord as living sacrifices as we strive to honour Him in everything we do.</p>

      <p>
        With all this in mind, we put a lot of importance into family worship on Sundays, bible study groups and we make time to pray together. We are careful to worship reverently and with joy. The
        preaching of the word of God has a vital place in our congregation.
      </p>

      <p>We enjoy fellowship with one another and nurture, love and care for one another.</p>

      <p>We also seek to find opportunities to bring the gospel to those who don't know the Lord, through invitation and reaching out to the neighborhood.</p>

      <p>We are well known for our annual outreach to children by conducting Holiday Bible Club. We run two youth meetings on a Friday and various Sunday school classes.</p>

      <p>We reach out to the needy in our area in benevolence as we also bring them the good news of God.</p>

      <p>We are connected and even serve with and pray for various mission organizations. <a href="/contact-us">Contact us</a> for more information about this.</p>
    </section>

    <div class="words">
      <div class="word">Community</div>
      <div class="word">Family</div>
      <div class="word">Ministry</div>
    </div>
  </article>`;
};

