import { html } from 'lit-html';
import './podcast-card.scss';
import { dataLayerPush } from '../../lib/dataLayer';
import { Feed, Meta } from '../../pages/sermon/sermon.models.v2';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { update } from '../..';

export const PodcastCard = (entity: Feed, met: Meta) => {
  const audioId = `audio-${entity.guid}`;
  const progressId = `progress-${entity.guid}`;

  const audioFile = new Audio(entity.enclosure.url);

  audioFile.ontimeupdate = () => {
    const progress = document.getElementById(progressId) as HTMLProgressElement;
    progress.max = audioFile.duration;
    progress.value = audioFile.currentTime;
  };

  return html`
    <div class="podcast-card">
      <div class="inner">
        <figure
          @click="${({ currentTarget }) => {
            const progress = document.getElementById(progressId) as HTMLProgressElement;
            const host = currentTarget as HTMLElement;
            host.classList.toggle('playing');

            progress.style.visibility = 'visible';

            if (audioFile.paused) {
              audioFile.play();
            } else audioFile.pause();
          }}"
        >
          <img src="${entity.itunes.image}" alt="${entity.title}" />

          <div class="play">
            <svg fill="none" viewBox="0 0 75 86"><path fill="var(--background)" d="M75 42.8571 0 85.7143V0l75 42.8571Z" /></svg>
          </div>

          <div class="pause">
            <svg fill="none" viewBox="0 0 75 86"><path fill="var(--background)" d="M5 0h20v86H5zM50 0h20v86H50z" /></svg>
          </div>
        </figure>

        <div class="content">
          <h5>${entity.title}</h5>

          <div class="episode-content">${unsafeHTML(entity.content)}</div>

          <div class="episode-action">
            <a href="${entity.link}" target="_blank">View on Anchor.FM</a>
          </div>

          <div class="episode-progress">
            <progress id="${progressId}"></progress>
          </div>
        </div>
      </div>
    </div>
  `;
};

/**
 * 
 *               @play="${() => {
                dataLayerPush({
                  event: 'PLAY_SERMON',
                  eventRef: `${entity.pastor} | ${entity.title}`,
                });
              }}"
 * 
 *         <a href="${entity.link}" target="_blank">
            <span>${entity.title}</span>
          </a>
 *    <!-- <a
      href="${entity.file}"
      target="_blank"
      download="${entity.name}"
      class="sermon-card"
      @click="${() => {
        dataLayerPush({
          event: 'DOWNLOAD_SERMON',
          eventRef: `${entity.name}`,
        });
      }}"
    >
      <div class="label">
        <h5>${entity.name}</h5>
        <p>Uploaded: ${new Date(entity.date).toDateString()}</p>
      </div>
    </a> -->
 */
