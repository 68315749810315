import { html } from 'lit-html';
import { update } from '../index';
import { NavDef, NavParams } from '../models/nav';

let routescahce: Array<NavDef>;

interface InternalNavDef extends NavDef {
  params?: NavParams;
}

export function useNavigation(routes?: Array<NavDef>) {
  routes = routescahce || routes;

  const route = window.location.pathname.substring(1).split('/');

  const mappedroutes = routes.map(m => parseRoute(route, m));

  let match = mappedroutes.find(r => `${r.path}` === route.join('/'));

  if (!!match) {
    match.helmet && match.helmet.apply();
    return match.page(
      match.params,
      new URLSearchParams(window.location.search)
    );
  } else {
    const fallback = mappedroutes.find(r => r.path === '**');
    if (!!fallback) {
      if (fallback.page !== undefined) {
        return fallback.page({ failedRoute: route.join('/') });
      } else if (fallback.redirectTo !== undefined) {
        navigate(fallback.redirectTo);
        return null;
      }
    }
  }

  return html`
    <h1>404 not found</h1>
  `;
}

function parseRoute(segs: string[], struct: NavDef): InternalNavDef {
  const matches = {};

  const parts = struct.path.split('/').map((s, i) => {
    if (s.startsWith(':')) {
      const name = s.replace(':', '').trim();
      const match = segs[i];
      matches[name] = match;
      return match;
    }

    return s;
  });

  return {
    page: struct.page || undefined,
    redirectTo: struct.redirectTo,
    path: parts.join('/'),
    params: matches,
    helmet: struct.helmet,
  };
}

export function navigate(url: string, event?: Event): void {
  if (!!event) {
    event.preventDefault();
    event.stopPropagation();
  }

  window.scrollTo(0, 0);
  window.history.pushState(null, null, url);
  update();
}

window.addEventListener('popstate', () => update());
